body {
  display: block;
  background-color: rgb(245, 247, 250);
  color: #323232;
}
a {
  color: #323232;
  text-decoration: none;
  &:hover {
    color: $accent-color;
  }
}

.app {
  display: flex;
  width: 100%;
}

.loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.card {
  border: none;
  box-shadow: 0 3px 3px rgba(115, 130, 145, 0.05),
    0 3px 3px rgba(115, 130, 145, 0.05) !important;
  &.hover-shadow:hover {
    box-shadow: 0 14px 28px rgba(115, 130, 145, 0.25),
      0 10px 10px rgba(115, 130, 145, 0.15) !important;
  }
}

.popover {
  max-width: 390px;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
  transition: all 0.3s ease;
  z-index: 9999;

  &.active {
    margin-left: -250px;
  }

  @media (max-width: 990px) {
    position: fixed;
    background-color: #fff;
    margin-left: -250px;
    box-shadow: 3px 0 3px rgba(115, 130, 145, 0.05),
      3px 0 3px rgba(115, 130, 145, 0.05) !important;
    &.active {
      margin-left: 0;
    }
  }
  .nav-link {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    padding-left: 25px;
    &.active {
      background-color: rgba(255, 255, 255, 0.8);
      i {
        color: $accent-color;
      }
    }

    i {
      color: rgba(33, 33, 33, 0.2);
      font-size: 1.7rem;
      transition: all 0.3s ease;
    }
    span {
      color: #323232;
      margin-left: 12px;
      font-weight: 500;
      font-size: 0.9rem;
      transition: all 0.3s ease;
    }
  }
  .sidebar-header {
    padding: 0 18px;
    height: $headers-height;
    .brand {
      font-weight: bold;
      color: #323232;
      text-decoration: none;
      font-size: 1.8rem;
    }
  }
}

#overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  z-index: 9998;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
#overlay.active {
  display: block;
  opacity: 1;
}

header {
  nav {
    height: $headers-height;
  }
  #returnButton {
    transition: all 0.8s ease;
    font-size: 1.5rem;
    padding: 0px 12px;
    &:hover {
      background-color: #fff;
    }
  }
  .navDropdownButton {
    position: relative;
    transition: all 0.3s ease;
    font-weight: bold;
    padding-left: 9px;
    padding-right: 9px;
    &:hover,
    &.active {
      background-color: #fff !important;
      box-shadow: 0 2px 4px #ddd;
    }
  }
}

.creator-card {
  text-align: center;
  transition: all 0.4s ease;
  &:hover {
    color: #555;
    transform: translateY(-5px);
    i {
      color: $accent-color;
    }
  }
  i {
    font-size: 3.5rem;
    color: #ddd;
    transition: all 0.2s ease;
  }
  h5 {
    margin-top: 1rem;
  }
}

.product-image-preview {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  .food-icon {
    color: #abbfd6;
    font-size: 6em;
  }
  .product__image-file {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.001;
    z-index: 888;
  }
  .product__change-image-btn {
    position: absolute;
    bottom: 10%;
    left: 50%;
    margin-left: -15%;
  }
}

.profit {
  font-size: 0.8em;
}

.providers,
.deliverers {
  .panel {
    .actions {
      border-bottom: 1px solid #eee;
    }
    .options {
      right: 0;
    }
  }
  .panel.list,
  .panel.new {
    flex: 1;
  }
  .panel.info {
    display: flex;
    flex: 3;
    .provider {
      flex: 1;
    }
    .products {
      flex: 1;
    }
    .deliverer-profile {
      flex: 1;
    }
    .deliveries {
      flex: 2;
    }
  }
}

.time-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  &.correct {
    border: 1px solid rgb(30, 201, 107);
  }
  .colon {
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
  }
  input[type="text"] {
    flex: 1;
    flex-grow: 1;
    border: none;
    border-radius: 4px;
    transition: all 0.8s ease;
    width: 28px;
    text-align: center;
    &::placeholder {
      color: #666;
      text-align: center;
    }
    &:focus {
      outline: none;
      background-color: rgb(183, 206, 250);
    }
  }
}

.orders {
  .orders__item-row {
    border-top: 1px solid #ddd;
    &:first-child {
      border-top: none;
    }

    .orders__item-type {
      font-size: 0.8em;
      color: #666;
    }
  }
}

.CreateTask {
  &__search {

    &-header {
      font-size: 16px;
    }

    &-row {
      display: flex;
      align-items: center;
      margin: 2px 0;
    }

    &-user {
      display: flex;
      flex: 1;
      align-items: center;
    }

    &-image {
      img {
        width: 32px;
        height: 32px;
        border-radius: 50px;
        object-fit: cover;
      }
    }

    &-name {
      margin-left: 7px;
    }
  }
}
