.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $headers-height;
  padding: 0 25px;
}
.navBar .navbar_left {
}

.navBar .navbar_right {
  display: flex;
  align-items: center;
}

.navBar .navbar_right .icon_wrap {
  cursor: pointer;
}

.navBar .navbar_right .notifications {
  margin-right: 25px;
}

.navBar .navbar_right .notifications .icon_wrap {
  position: relative;
  width: 35px;
  font-size: 25px;
  text-align: center;
}

.notifications .icon_wrap .badge {
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  font-size: 10px;
  line-height: 12px;
  padding: 0;
  background-color: #ff1e56;
  color: #fff;
  bottom: 8px;
  right: 3px;
  border-radius: 50%;
  animation: beat 1s infinite;
}

.navBar .navbar_right .profile .icon_wrap .user-icon {
  font-size: 25px;
}

.navBar .navbar_right .profile,
.navBar .navbar_right .notifications {
  position: relative;
}

.notification_ul,
.profile_ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navBar .profile .profile_dd,
.notification_dd {
  position: absolute;
  top: 48px;
  right: -10px;
  user-select: none;
  background: #fff;
  border: 1px solid #c7d8e2;
  width: 420px;
  height: auto;
  display: none;
  border-radius: 3px;
  box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.125),
    -10px -10px 35px rgba(0, 0, 0, 0.125);
  z-index: 999;
}

.navBar .profile .profile_dd:before,
.notification_dd:before {
  content: "";
  position: absolute;
  top: -20px;
  right: 15px;
  border: 10px solid;
  border-color: transparent transparent #fff transparent;
}

.notification_dd .view_all {
  color: #3b80f9;
  border-top: 1px solid #f1f2f4;
  &:hover {
    background: #f0f5ff;
  }
}

.notification_ul li {
  display: flex;
  border-top: 1px solid #f1f2f4;
  padding: 7px 7px;
}

.notification_ul li:first-child {
  border-top: none;
}

.notification_ul li .icon-container {
  flex-basis: 38px;
}

.notification_ul li .icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: #ff1e56;
}

.notification_ul li .icon-circle .icon {
  color: #fff;
  font-size: 1.2em;
}

.notification_ul li .notify_data {
  flex: 4;
  margin: 0 10px;
  // width: 285px;
}

.notification_ul li .notify_data .title {
  color: #323232;
  font-weight: 600;
  line-height: 1.4em;
}

.notification_ul li .notify_data .sub_title {
  font-size: 14px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #444;
  line-height: 1.2em;
}

.notification_ul li .notify_data .date {
  color: #b8b8b8;
}

.notification_ul li .notify_action {
  flex: 1;
  align-self: center;
  text-align: right;
}

.navBar .navbar_right .profile .icon_wrap {
  display: flex;
  align-items: center;
}

.navBar .navbar_right .profile .name {
  display: inline-block;
  margin: 0 10px;
}

.navBar .navbar_right .icon_wrap:hover,
.navBar .navbar_right .profile.active .icon_wrap,
.navBar .navbar_right .notifications.active .icon_wrap {
  color: #3b80f9;
}

.navBar .profile .profile_dd {
  width: 225px;
}
.navBar .profile .profile_dd:before {
  right: 10px;
}

.navBar .profile .profile_dd ul li {
  border-bottom: 1px solid #f1f2f4;
}

.navBar .profile .profile_dd ul li a {
  display: block;
  padding: 15px 35px;
  position: relative;
}

.navBar .profile .profile_dd ul li a .picon {
  display: inline-block;
  width: 30px;
}

.navBar .profile .profile_dd ul li a:hover {
  color: #3b80f9;
  background: #f0f5ff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.navBar .profile .profile_dd ul li.profile_li a:hover {
  background: transparent;
  cursor: default;
  color: #7f8db0;
}

.navBar .profile .profile_dd ul li .btn {
  height: 32px;
  padding: 7px 10px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  background: #3b80f9;
  width: 125px;
  margin: 5px auto 15px;
}

.navBar .profile .profile_dd ul li .btn:hover {
  background: #6593e4;
}

.navBar .profile.active .profile_dd,
.navBar .notifications.active .notification_dd {
  display: block;
}

@keyframes beat {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.3) translateX(9%) translateY(10%);
  }
}
